
import { getIndustriesList } from '@/api/industriesAndLocation';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import BaseInput from '@/components/baseComponents/BaseInput.vue';
import BaseSelect from '@/components/baseComponents/BaseSelect.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    BaseButton,
    BaseInput,
    BaseSelect,
  },
})
export default class LandingValuationPage extends Vue {
  $refs!: {
    options: HTMLElement;
  };

  optionsIndustries = [];
  errors = {
    annualRevenueLevel: false,
    industry: false,
  };
  appsBenefits = [
    {
      title: 'Determine the fair market value of your business',
      text: "Value your small business beforehand so an investor or potential partner can see you've done your due diligence",
    },
    {
      title: 'Scenario Modeling',
      text: 'Businesses can envision different scenarios to track how adjustments in future sales, marketing, HR, operations, and financial decisions can influence outcomes',
    },
    {
      title: 'Get actionable financial insights',
      text: 'Get accurate cash flow predictions with configurable and extensible financial models',
    },
    {
      title: 'Get business purchase offers',
      text: 'Get business purchase offers from our partners with a verified status of your business with transparent accounting',
    },
    {
      title: 'Check your business financial wellness for free',
      text: 'By analyzing your operational transactions, revenue/profits, market value & other KPI CrediPush can easily highlight the critical issues and compare it with market standards',
    },
    {
      title: 'Monitor and boost your business credit score',
      text: 'As the official partner of CreditSafe, LexisNexis, Experian, Equifax and other credit bureaus CreditPush gives you an opportunity to check your business credit score and boost it',
    },
  ];

  get annualRevenueLevel(): string {
    return this.$store.state.user.annualRevenueLevel;
  }
  set annualRevenueLevel(value: string) {
    this.$store.commit('user/setAnnualRevenueLevel', value);
  }
  get industry(): string {
    return this.$store.state.user.industryForBusinessValuation;
  }
  set industry(value: string) {
    this.$store.commit('user/setIndustryForBusinessValuation', value);
  }

  async assignmentIndustries(): Promise<void> {
    try {
      const optionsIndustriesArr = ((await getIndustriesList()) as any).data;
      this.optionsIndustries = this.industriyByLang(optionsIndustriesArr, 'en-US');
    } catch (error: any) {
      this.popupError(error.response.data.message);
    }
  }
  industriyByLang(industriesArr: any, lang: string): never[] {
    return industriesArr.map((item: any) => {
      const name = JSON.parse(item.name)[lang];
      return { ...item, name };
    });
  }
  checkField(field: string): void {
    if (field === 'annualRevenueLevel') this.errors.annualRevenueLevel = Number(this.annualRevenueLevel) === 0;
    if (field === 'industry') this.errors.industry = Boolean(!this.industry);
  }
  isAllFieldsCorrect(): boolean {
    this.checkField('annualRevenueLevel');
    this.checkField('industry');
    return !this.errors.annualRevenueLevel && !this.errors.industry;
  }
  goToBusinessValuePage(): void {
    if (this.isAllFieldsCorrect()) this.$router.push({ name: 'BusinessValuePage' });
  }
  scrollToCalculate(): void {
    (this.$refs as any).form.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
  popupError(errorText: string): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: errorText,
    });
  }

  created(): void {
    this.assignmentIndustries();
  }

  mounted(): void {
    this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion: '508', pageName: this.$route.name });
  }
}
